import SharedHeading from '@/components/heading';
import { CSSProperties } from 'react';

/* WHEN CHANGING THE HEADING CLASSES ALSO CHANGE THE CLASSES IN THE PROSE CLASSES FILE */
const headingClasses = {
  1: 'mb-4 text-primary-text text-2xl @md:text-3xl @lg:text-4xl',
  2: 'mb-4 text-primary text-xl @md:text-2xl',
  3: 'mb-2 text-primary',
  5: 'mb-2 text-primary',
  6: 'mb-2 text-primary',
};

export default function Heading({
  children,
  ...otherProps
}: {
  level?: number;
  styleLevel?: number;
  className?: string;
  children: React.ReactNode;
  style?: CSSProperties;
}): JSX.Element {
  return (
    <SharedHeading headingClasses={headingClasses} {...otherProps}>
      {children}
    </SharedHeading>
  );
}
