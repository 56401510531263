import { twMerge } from 'tailwind-merge';

export const ARROW_CLASS_NAME = 'h-10 w-10 @lg:h-14 @lg:w-14 fill-primary';

export default function NextPreviousButtons({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode[] | undefined;
}) {
  return (
    <div
      className={twMerge(
        'hidden @sm:flex w-full justify-between absolute top-1/2 translate-y-[-50%] print:hidden',
        className
      )}
    >
      {children}
    </div>
  );
}
