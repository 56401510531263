'use client';

import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import CarouselSkeleton from './carousel.skeleton';
import { useEffect, useMemo, useState } from 'react';
import { CarouselProps } from './carousel.component';


export default function LazyCarousel({
  slideCount,
  carouselFor,
  dots,
  children,
}: CarouselProps & { slideCount: number }) {
  const SliderCarousel = useMemo(
    () =>
      // @ts-ignore - argument types for dynamic appear to be treat the options as the first argument instead of import callback
      dynamic<CarouselProps>(() => import('./carousel.component'), {
        loading: () => <CarouselSkeleton slideCount={slideCount} carouselFor={carouselFor} />,
      }),
    [slideCount, carouselFor]
  );

  // TODO: Is this what we want?  Can we find an alternative way to lazy load this as soon as everything else has loaded
  // for a better UX.  Otherwise it doesn't load until it's on the screen causing a poor UX.  I presume this is done to
  // imporove page speed metrics, is there a after page loaded hook that we can hook into that doesn't degrade page
  // speed metrics but loads content in the background after the main content of the page has loaded but before you try
  // to view it?

  // lazy-load the carousel only when it comes into view to avoid loading it before it's needed
  const [viewed, setViewed] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  

  useEffect(() => {
    if (inView) setViewed(true);
  }, [inView]);
  return (
    <div ref={ref}>
      {viewed ? (
        <>
          <SliderCarousel dots={dots}>{children}</SliderCarousel>
        </>
      ) : (
        <CarouselSkeleton slideCount={slideCount} carouselFor={carouselFor} />
      )}
    </div>
  );
}
