import { TextareaHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { inputClasses } from './input.component';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

// forwardRef is required here as react-hook-form passes a ref to
// form fields that would otherwise break function components
const TextArea = forwardRef<HTMLTextAreaElement, InputProps>(
  ({ className, ...otherProps }: TextareaHTMLAttributes<HTMLTextAreaElement>, ref) => {
    return <textarea className={twMerge(inputClasses, className)} ref={ref} {...otherProps} />;
  }
);

// prevents linting error 'Component definition is missing display name'
TextArea.displayName = 'TextArea';

export default TextArea;
