import Slide from './components/slide.component';
import StaffCardSkeleton from '../cards/staff-card.skeleton';
import Grid from '@rigel/components/grid.component';
import Container from '@rigel/components/container.component';
import { CarouselForType } from './carousel.component';

type CarouselSkeletonType = {
  slideCount: number;
  carouselFor?: CarouselForType;
};

export default function CarouselSkeleton({ slideCount, carouselFor = null }: CarouselSkeletonType) {
  return (
    <>
      {(() => {
        switch (carouselFor) {
          case 'staff':
            return (
              <Container>
                <Grid columns={3}>
                  <StaffCardSkeleton />
                  <StaffCardSkeleton className="hidden @sm:flex" />
                  <StaffCardSkeleton className="hidden @lg:flex" />
                </Grid>
              </Container>
            );
          case 'testimonials':
            return (
              <div className="w-full h-24 flex items-center justify-center flex-col">
                <div className="w-2/3 h-9 rounded mb-10 bg-slate-200 animate-pulse" />
                <div className="w-1/3 h-8 rounded bg-slate-200 animate-pulse" />
              </div>
            );
          default:
            // old skeleton
            return (
              <div className="relative @lg:mx-auto">
                <div className="overflow-hidden">
                  <div className="flex h-full relative">
                    {Array.from({ length: slideCount }, (_, i) => i).map((el) => (
                      <Slide key={el} slideCount={slideCount}>
                        <div className="w-full h-72 bg-slate-200 animate-pulse" />

                        <div className="p-6 bg-light-grey-striped">
                          <div className="pt-2 flex flex-col justify-around gap-4 w-full">
                            <div className="w-full h-9 rounded bg-slate-200 animate-pulse" />
                            <div className="w-40 h-8 rounded bg-slate-200 animate-pulse" />
                            <div className="w-40 h-8 rounded bg-slate-200 animate-pulse" />
                            <div className="w-40 h-8 rounded bg-slate-200 animate-pulse" />
                          </div>
                        </div>
                      </Slide>
                    ))}
                  </div>
                </div>
              </div>
            );
        }
      })()}
    </>
  );
}
