import { InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

export const inputClasses = 'block bg-white w-full border border-gray-400 p-2 text-sm';

// forwardRef is required here as react-hook-form passes a ref to
// form fields that would otherwise break function components
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...otherProps }: InputHTMLAttributes<HTMLInputElement>, ref) => {
    return <input className={twMerge(inputClasses, className)} ref={ref} {...otherProps} />;
  }
);

// prevents linting error 'Component definition is missing display name'
Input.displayName = 'Input';

export default Input;
