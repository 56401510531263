import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import type { EmblaCarouselType } from 'embla-carousel';

import BlockLeft from '@rigel/svgs/block-left';

import { ARROW_CLASS_NAME } from './next-previous-buttons.component';

export default function PreviousButton({
  className,
  emblaApi,
}: {
  className?: string;
  emblaApi?: EmblaCarouselType;
}) {
  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  return (
    <button onClick={scrollPrev} aria-label="Previous image">
      <BlockLeft className={twMerge(ARROW_CLASS_NAME, className)} />
    </button>
  );
}
