export default function BlockLeft({ className }: { className?: string }) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="title"
      className={className}
    >
      <title id="title">Left</title>
      <rect width="60" height="60" />
      <path d="M36 16L21 31L36 46" stroke="white" strokeWidth="4" strokeLinecap="square" />
    </svg>
  );
}
