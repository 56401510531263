import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import type { EmblaCarouselType } from 'embla-carousel';

import BlockRight from '@rigel/svgs/block-right';

import { ARROW_CLASS_NAME } from './next-previous-buttons.component';

export default function PreviousButton({
  className,
  emblaApi,
}: {
  className?: string;
  emblaApi?: EmblaCarouselType;
}) {
  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  return (
    <button onClick={scrollNext} aria-label="Next image">
      <BlockRight className={twMerge(ARROW_CLASS_NAME, className)} />
    </button>
  );
}
