'use client';

import { useEffect, useMemo, useRef } from 'react';
import { LuMaximize2 } from 'react-icons/lu';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

import NextPreviousButtons from './components/next-previous-buttons.component';
import PreviousButton from './components/previous-button.component';
import NextButton from './components/next-button.component';
import { twMerge } from 'tailwind-merge';

export type SliderProps = {
  className?: string;
  arrows?: boolean;
  expanded?: boolean;
  setExpanded?: (newValue: boolean) => void;
  autoPlay?: boolean;
  children: React.ReactNode[] | undefined;
  delay?: number;
};

export default function Slider({
  expanded,
  setExpanded,
  arrows,
  autoPlay,
  children,
  className,
  delay,
}: SliderProps) {
  const carouselRef = useRef<HTMLSelectElement | null>(null);

  const handleExpand = () => {
    if (setExpanded) {
      const carousel = carouselRef.current;
      if (!expanded) {
        carousel?.requestFullscreen();
        setExpanded(true);
      } else {
        document.exitFullscreen();
        setExpanded(false);
      }
    }
  };

  const singleItem = useMemo(() => children?.length === 1, [children]);

  useEffect(() => {
    if (!setExpanded) return;

    function collapseStyles() {
      if (setExpanded && !document.fullscreenElement) {
        setExpanded(false);
      }
    }

    document.addEventListener('fullscreenchange', collapseStyles);

    return () => document.removeEventListener('fullscreenchange', collapseStyles);
  }, []);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { align: 'start', loop: true },
    autoPlay ? [Autoplay({ playOnInit: true, delay: delay ? delay * 1000 : 3000 })] : undefined
  );

  return (
    <section className={twMerge('rigel-gallery-carousel', className)} ref={carouselRef}>
      <div className="relative group group/carousel h-full">
        <div className="overflow-x-clip h-full" ref={emblaRef}>
          <div className="flex h-full relative">{children}</div>
        </div>

        {arrows && !singleItem && (
          <NextPreviousButtons>
            <PreviousButton emblaApi={emblaApi} />
            <NextButton emblaApi={emblaApi} />
          </NextPreviousButtons>
        )}
        {setExpanded && (
          <button
            onClick={handleExpand}
            className="absolute p-2 top-0 right-0 focus:outline-none bg-primary text-white"
          >
            <LuMaximize2 className="h-6 w-6 @lg:h-10 @lg:w-10" />
          </button>
        )}
      </div>
    </section>
  );
}
