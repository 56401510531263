import { UseFormRegister, FieldErrors } from 'react-hook-form';
import Label from './label.component';
import Checkbox from './checkbox.component';
import { SerializableAgency } from '@/serializers/agency';
import ErrorMessage from './error-message.component';
import { twMerge } from 'tailwind-merge';
import { ReactNode, useId } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@homeflow/next/state';

type MarketingPreferencesProps = {
  agency: SerializableAgency;
  registerValidation: UseFormRegister<any>;
  className?: string;
  errors: FieldErrors;
  children?: ReactNode;
};

export default function MarketingPreferences({
  agency,
  registerValidation,
  className,
  errors,
  children,
}: MarketingPreferencesProps) {
  const currentUser = useSelector(selectCurrentUser);
  // unique identifier required for checkbox labels to work as marketing
  // preferences used in multiple forms
  const preferencesId = useId();

  return (
    <div
      className={twMerge(
        'flex flex-wrap gap-5 text-xs @md:text-sm w-full justify-between items-center',
        className
      )}
    >
      {!currentUser?.isOptedInForMarketing && (
        <div className="flex flex-row gap-4 basis-full grow shrink-0">
          <Checkbox
            className="w-3 basis-3 shrink-0"
            id={`optInMarketing-${preferencesId}`}
            {...registerValidation('optInMarketing', {})}
          />

          <Label
            dangerouslySetInnerHTML={{
              __html: agency.marketingStatementText,
            }}
            htmlFor={`optInMarketing-${preferencesId}`}
            className="font-normal [&_a]:underline [&_a]:font-bold mb-0"
          />
        </div>
      )}

      <div className="relative">
        <div className="flex flex-row gap-4">
          <Checkbox
            className="w-3 basis-3 shrink-0"
            id={`optInTerms-${preferencesId}`}
            {...registerValidation('optInTerms', { required: 'Please check the box' })}
          />

          <Label
            dangerouslySetInnerHTML={{ __html: agency.termsStatementText }}
            htmlFor={`optInTerms-${preferencesId}`}
            className="font-normal [&_a]:underline [&_a]:font-bold mb-0"
          />
        </div>
        {errors.optInTerms && <ErrorMessage>{`${errors.optInTerms.message}`}</ErrorMessage>}
      </div>
      {children && children}
    </div>
  );
}
