import { InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {}

// forwardRef is required here as react-hook-form passes a ref to
// form fields that would otherwise break function components
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, ...otherProps }: InputHTMLAttributes<HTMLInputElement>, ref) => {
    return <input className={twMerge(className)} ref={ref} type="checkbox" {...otherProps} />;
  }
);

// prevents linting error 'Component definition is missing display name'
Checkbox.displayName = 'Checkbox';

export default Checkbox;
