import { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

export default function Label({
  children,
  htmlFor,
  className,
  ...otherProps
}: ComponentPropsWithRef<'label'>) {
  return (
    <label htmlFor={htmlFor} className={twMerge('font-semibold mb-2', className)} {...otherProps}>
      {children}
    </label>
  );
}
