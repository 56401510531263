import { ComponentProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const COLUMN_CLASS_NAME: { [key: number]: string } = {
  3: '@sm:grid-cols-2                 @lg:grid-cols-3',
  4: '@sm:grid-cols-2 @md:grid-cols-3 @lg:grid-cols-4',
};

interface Props extends ComponentProps<'div'> {
  children: ReactNode;
  columns: number;
}
export default function Grid({ columns, children, ...otherProps }: Props) {
  return (
    <div
      className={twMerge('grid gap-6 @lg:gap-10 mb-4 @lg:mb-10', COLUMN_CLASS_NAME[columns])}
      {...otherProps}
    >
      {children}
    </div>
  );
}
