import { PropsWithChildren } from 'react';

const SLIDE_CLASS_NAME: { [key: number]: string } = {
  3: 'w-4/5 @md:w-slide-1/2 @lg:w-slide-1/3 grow-0 shrink-0 mr-10',
  4: 'w-4/5 @sm:w-slide-1/2 @lg:w-slide-1/3 @xl:w-slide-1/4 grow-0 shrink-0 mr-10',
};
export default function Slide({
  slideCount,
  children,
}: PropsWithChildren & { slideCount: number }) {
  return <div className={SLIDE_CLASS_NAME[slideCount]}>{children}</div>;
}
